import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Stack } from 'grommet';
import { get, startCase } from '../../lib/nodash';

import HeroProductDetail from '../HeroProductDetail';

import Buybar from '../Buybar';
import ModalPaintCalculator from '../Calculator/ModalPaintCalculator';

import { findMetafieldValue, findImage } from '../../lib/product';
import deNodify from '../../lib/deNodify';
import PaintBuyOptions from './PaintBuyOptions';

const PagePaintPrimer = ({ product, content }) => {
  const { primerProducts } = useStaticQuery(graphql`
    query PrimerProducts {
      primerProducts: allShopifyProduct(
        filter: { productType: { regex: "/primer/i" } }
      ) {
        edges {
          node {
            handle
            shopifyId
            title
            productType
            tags
            featuredImage {
              originalSrc
            }
            media {
              ... on ShopifyMediaImage {
                image {
                  originalSrc
                }
              }
            }
            variants {
              shopifyId
              title
              price
            }
          }
        }
      }
    }
  `);
  const shortDescription = findMetafieldValue(
    'paint_description',
    product.metafields,
    'accentuate'
  );

  const { description, title, productType, variants } = product;
  const heroImage = get('featuredImage', product);
  const collectionImage =
    findImage(
      product.media.map(({ image }) => image),
      '_grid'
    ) ||
    findImage(
      product.media.map(({ image }) => image),
      '_square'
    );

  const borderSettings = { side: 'bottom', size: 'small', color: 'black' };
  const products = deNodify(primerProducts);
  const paintCollection = {
    title: product.title,
    handle: 'primer',
    shopifyId: 'primer',
    description: product.description,
    metafields: [
      {
        key: 'hero_image',
        namespace: 'collection',
        value: products[0]?.featuredImage,
      },
      {
        key: 'collection_image',
        namespace: 'images',
        value: {
          originalSrc:
            findImage(
              products[0]?.media?.map(({ image }) => image),
              '_grid'
            ) ||
            findImage(
              products[0]?.media?.map(({ image }) => image),
              '_square'
            ),
        },
      },
    ],
    products: products.map((x) => ({
      ...x,
      productType: startCase(x.title.replace('PRIMER', '')),
    })),
  };

  return (
    <Stack anchor="bottom">
      <Box>
        <HeroProductDetail
          variants={variants}
          title={title}
          description={description}
          shortDescription={shortDescription}
          border={borderSettings}
          heroImage={heroImage}
          type={'Paint'}
          productType={productType}
        />
        {content}
      </Box>
      <Buybar>
        <PaintBuyOptions
          products={products}
          paintCollection={paintCollection}
          dark={false}
          product={product}
          imageUrl={collectionImage}
          productType={productType}
          maxQty={10}
          buttonText="Add"
        />
      </Buybar>
      <ModalPaintCalculator
        product={product}
        collection={paintCollection}
        productGroup={paintCollection}
      />
    </Stack>
  );
};

PagePaintPrimer.propTypes = {
  product: PropTypes.object.isRequired,
  content: PropTypes.node,
};

export default memo(PagePaintPrimer);
